import type {
    TravelFormDurationPreset,
    TravelFormDurationSectionKey,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import Counter from '@/core/components/counter/counter';
import RangeSlider from '@/core/components/range-slider/range-slider';
import { Columns } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './travel-form-duration-desktop.module.scss';

const bem = bemModule(styles);

export type TravelFormDurationDesktopProps = {
    activeModule: TravelFormDurationSectionKey;
    activePresetChipText: null | string;
    currentRangeValue: { max: number; min: number };
    dayCounter: number;
    dayRangeMax: number;
    dayRangeMin: number;
    exactDays: { text: string; value: string } | null;
    onActiveModuleChange: (activeModule: TravelFormDurationSectionKey) => void;
    onCalendarPickClick: (exactDays: string) => void;
    onDayCountDecrease: (nextCount: number) => void;
    onDayCountIncrease: (nextCount: number) => void;
    onPresetChipClick: (presetChip: TravelFormDurationPreset) => void;
    onRangeChange: (range: { max: number; min: number }) => void;
    presetChips: TravelFormDurationPreset[];
    rangeStep: number;
};

export default function TravelFormDurationDesktop({
    activeModule,
    activePresetChipText,
    currentRangeValue,
    dayCounter,
    dayRangeMax,
    dayRangeMin,
    exactDays,
    onActiveModuleChange,
    onCalendarPickClick,
    onDayCountDecrease,
    onDayCountIncrease,
    onPresetChipClick,
    onRangeChange,
    presetChips,
    rangeStep,
}: TravelFormDurationDesktopProps) {
    const handlePresetChipClick = (presetChip: TravelFormDurationPreset) => {
        onActiveModuleChange('preset');
        return onPresetChipClick(presetChip);
    };

    const handleDayCountDecrease = (nextValue: number) => {
        onActiveModuleChange('counter');
        return onDayCountDecrease(nextValue);
    };

    const handleDayCountIncrease = (nextValue: number) => {
        onActiveModuleChange('counter');
        return onDayCountIncrease(nextValue);
    };

    const handleOnRangeChange = (range: { max: number; min: number }) => {
        onActiveModuleChange('range');
        return onRangeChange(range);
    };

    const handleExactDaysClick = (exactDays: string) => {
        onActiveModuleChange('exactDays');
        return onCalendarPickClick(exactDays);
    };

    return (
        <div className={styles.overlayContent}>
            <ContentContainer>
                <Columns
                    count={3}
                    disableLastItemStretch={true}
                    gap={14}
                >
                    {presetChips.map((presetChip, index) => {
                        return (
                            <div
                                className={bem(styles.presetChip, {
                                    isActive: presetChip.value === activePresetChipText && activeModule === 'preset',
                                })}
                                data-qa-id={'qa-travel-form-duration-preset-chip-desktop'}
                                key={`${presetChip.value}-${index}`}
                                onClick={() => handlePresetChipClick(presetChip)}
                            >
                                {presetChip.text}
                            </div>
                        );
                    })}
                </Columns>
            </ContentContainer>
            <ContentContainer>
                <BorderedContainer
                    isActive={activeModule === 'counter'}
                    onClick={() => onActiveModuleChange('counter')}
                >
                    <div className={styles.dayCounterWrapper}>
                        <span>Genau</span>
                        <Counter
                            count={dayCounter}
                            max={dayRangeMax}
                            min={dayRangeMin}
                            onDecrease={handleDayCountDecrease}
                            onIncrease={handleDayCountIncrease}
                        />
                        <span>Tage</span>
                    </div>
                </BorderedContainer>
            </ContentContainer>
            <ContentContainer>
                <BorderedContainer
                    isActive={activeModule === 'range'}
                    onClick={() => onActiveModuleChange('range')}
                >
                    <div className={styles.rangeSliderContainer}>
                        <div className={styles.rangeSliderWrapper}>
                            <RangeSlider
                                max={dayRangeMax}
                                min={dayRangeMin}
                                onChange={handleOnRangeChange}
                                step={rangeStep}
                                value={currentRangeValue}
                            />
                        </div>
                        <div className={'flex-space-between-horizontal'}>
                            <span className={styles.rangeNumber}>{`${currentRangeValue.min} Tage`}</span>
                            <span>bis</span>
                            <span className={styles.rangeNumber}>{`${currentRangeValue.max} Tage`}</span>
                        </div>
                    </div>
                </BorderedContainer>
            </ContentContainer>
            {exactDays && (
                <ContentContainer>
                    <BorderedContainer
                        isActive={activeModule === 'exactDays'}
                        onClick={() => onActiveModuleChange('exactDays')}
                    >
                        <div
                            className={`flex-center ${styles.exactDays}`}
                            onClick={() => handleExactDaysClick(exactDays.value)}
                        >
                            <span>Wie im Kalender angegeben</span>
                            <span className={styles.exactDaysLabel}>{exactDays.text}</span>
                        </div>
                    </BorderedContainer>
                </ContentContainer>
            )}
        </div>
    );
}

const ContentContainer = ({ children }: React.PropsWithChildren) => {
    return <div className={styles.contentContainer}>{children}</div>;
};
const BorderedContainer = ({
    children,
    isActive,
    onClick,
}: React.PropsWithChildren<{ isActive: boolean; onClick?: () => void }>) => {
    return (
        <div
            className={`flex-center ${bem(styles.borderedContainer, { isActive })}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
