import type { ImageType } from '@/core/features/image-v2/image-type';

import * as React from 'react';

import { ResponsiveImage } from '@/core/features';

import styles from './banner.module.scss';

export type BannerProps = React.PropsWithChildren & {
    className?: string;
    height: number;
    image: ImageType | null;
    imageOverlayColor?: string;
    marginBottom?: number;
};

export default function Banner({
    children,
    className,
    height,
    image,
    imageOverlayColor,
    marginBottom = 0,
}: BannerProps) {
    return (
        <div
            className={styles.banner}
            style={{
                height: `${height}px`,
                marginBottom: `${marginBottom}px`,
            }}
        >
            {image && (
                <div className={styles.image}>
                    <ResponsiveImage
                        alt={image.imageAlt}
                        fetchPriority={'high'}
                        height={height}
                        layoutWidth={100}
                        preload={true}
                        url={image.imageUrl}
                    />
                </div>
            )}
            <div
                className={`absolute-full ${styles.overlay}`}
                style={{
                    backgroundColor: imageOverlayColor,
                }}
            />
            <div className={`${styles.content} ${className ? className : ''}`}>{children}</div>
        </div>
    );
}
