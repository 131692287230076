import type { OnClickEvent } from '@/core/types/react-overrides';
import type {
    TravelFormAirportResult,
    TravelFormAirports,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import useLoadingIndicator from '@/core/components/loading/use-loading-indicator';
import { LoadingSpinnerIcon } from '@/core/features';
import IconImage from '@/core/features/image-v2/icon-image';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';
import { TravelFormSearchShimmerBoxDesktop } from '@/features/travel-form/travel-form-overlay/travel-form-shimmer-boxes-desktop';

import AirportItem from './travel-form-airport-item-desktop/travel-form-airport-item-desktop';

import styles from './travel-form-airport-desktop.module.scss';

const bem = bemModule(styles);

export type TravelFormAirportDesktopProps = {
    activeFiltersTitle?: { plural: string; singular: string };
    defaultAirport: TravelFormAirportResult | null;
    hasSearchQuery: boolean;
    isLoading: boolean;
    noResult: { iconUrl: string; text: string } | null;
    onAirportClick: (airport: TravelFormAirportResult, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    resultSet: TravelFormAirports[];
    selectedAirports: TravelFormAirportResult[];
};

export default function TravelFormAirportDesktop({
    activeFiltersTitle,
    defaultAirport,
    hasSearchQuery,
    isLoading,
    noResult,
    onAirportClick,
    resultSet,
    selectedAirports,
}: TravelFormAirportDesktopProps) {
    const showShimmerBoxLoading = resultSet.length === 0;
    const showLoadingIndicator = useLoadingIndicator(isLoading);

    if (showShimmerBoxLoading) {
        return <TravelFormSearchShimmerBoxDesktop />;
    }

    return (
        <>
            {!hasSearchQuery && defaultAirport && (
                <>
                    <AirportItem
                        airport={defaultAirport}
                        hasCheckbox={false}
                        isDefault={true}
                        isSelected={isSelectedAirport(defaultAirport, selectedAirports)}
                        onAirportClick={onAirportClick}
                    />
                    <Divider
                        color={'gallery'}
                        marginLeft={20}
                        marginRight={20}
                    />
                </>
            )}
            <>
                {!hasSearchQuery && (
                    <SelectedResultItems
                        activeFiltersTitle={activeFiltersTitle}
                        onAirportClick={onAirportClick}
                        selectedAirports={selectedAirports}
                    />
                )}
            </>
            {resultSet.map((result) => (
                <div
                    className={bem(styles.contentWrapper, {
                        isLoading: isLoading || showLoadingIndicator,
                    })}
                    key={result.title}
                >
                    {!hasSearchQuery && (
                        <div className={'padding-left-20 padding-bottom-10 font-size-16 padding-top-10'}>
                            {result.title}
                        </div>
                    )}
                    <div className={styles.content}>
                        {noResult && (
                            <>
                                <div className={`flex-space-between-horizontal ${styles.noResultWrapper}`}>
                                    <div className={styles.noResultText}>{noResult.text}</div>
                                    <div className={styles.noResultIcon}>
                                        <IconImage
                                            alt={'no-result-icon'}
                                            height={35}
                                            url={noResult.iconUrl}
                                            width={35}
                                        />
                                    </div>
                                </div>
                                <Divider
                                    color={'gallery'}
                                    marginLeft={20}
                                    marginRight={20}
                                />
                            </>
                        )}
                        <UnselectedResultItems
                            airports={result.items}
                            hasSearchQuery={hasSearchQuery}
                            onAirportClick={onAirportClick}
                            selectedAirports={selectedAirports}
                        />
                    </div>
                    {showLoadingIndicator && (
                        <div className={styles.loadingSpinner}>
                            <LoadingSpinnerIcon color={color('doveGray')} />
                        </div>
                    )}
                </div>
            ))}
        </>
    );
}

const UnselectedResultItems = ({
    airports,
    hasSearchQuery,
    onAirportClick,
    selectedAirports,
}: {
    airports: TravelFormAirportResult[];
    hasSearchQuery: boolean;
    onAirportClick: (airport: TravelFormAirportResult, event: OnClickEvent) => void;
    selectedAirports: TravelFormAirportResult[];
}) => {
    const [savedSelectedAirports] = React.useState(hasSearchQuery ? [] : selectedAirports);

    const unselectedAirports = airports.filter((airport) => {
        return !savedSelectedAirports.some((selectedAirport) => selectedAirport.name === airport.name);
    });

    return unselectedAirports.map((airport) => {
        const isItemSelected = isSelectedAirport(airport, selectedAirports);
        return (
            !airport.isDefault && (
                <AirportItem
                    airport={airport}
                    isDefault={false}
                    isSelected={isItemSelected}
                    key={airport.code}
                    onAirportClick={onAirportClick}
                />
            )
        );
    });
};

const SelectedResultItems = ({
    activeFiltersTitle,
    onAirportClick,
    selectedAirports,
}: {
    activeFiltersTitle?: { plural: string; singular: string };
    onAirportClick: (airport: TravelFormAirportResult, event: OnClickEvent) => void;
    selectedAirports: TravelFormAirportResult[];
}) => {
    const [initialSelectedAirports] = React.useState(selectedAirports.filter((airport) => !airport.isDefault));

    if (initialSelectedAirports.length === 0) {
        return null;
    }

    return (
        <>
            {activeFiltersTitle && initialSelectedAirports.length > 0 && (
                <div className={'padding-left-20 padding-bottom-10 padding-top-10 font-size-16'}>
                    {initialSelectedAirports.length === 1 ? activeFiltersTitle.singular : activeFiltersTitle.plural}
                </div>
            )}
            {initialSelectedAirports.map((airport) => {
                const isItemSelected = isSelectedAirport(airport, selectedAirports);
                return (
                    <AirportItem
                        airport={airport}
                        isDefault={false}
                        isSelected={isItemSelected}
                        key={airport.code}
                        onAirportClick={onAirportClick}
                    />
                );
            })}
            <Divider
                color={'gallery'}
                marginLeft={20}
                marginRight={20}
            />
        </>
    );
};

const isSelectedAirport = (airport: TravelFormAirportResult, selectedAirports: TravelFormAirportResult[]) => {
    return selectedAirports.some((selectedAirport) => selectedAirport.name === airport.name);
};
